import { useState } from 'react';
import iconCheck from '../../../assets/icons/iconCheck.svg'
import { Order, ScheduleDetails } from '../../../types/types';
import { useCancelSchedule } from '../../../hooks/useCancelSchedule';
import Loading from '../../Loading';
import ModalCancelSchedule from '../ModalCancelSchedule';
import {
  CardSuccess,
  CardCancelled,
  ContainerCol,
  Title,
  TypeValidation,
  Paragraph,
  TitleValidation,
  Locale,
  Date,
  Time,
  Product,
  ProductName,
  ContentButtons,
  Button,
  Subtitle,
  ColumnIcon,
  ColumnText,
  ContentButtonsCancelled,
  ButtonCancelled,
  Icon,
  TextTitle,
  ParagraphCancelled
} from './styles';

interface ScheduleMadeProps {
  scheduleInfos: ScheduleDetails;
  order: Order | null;
  onSchedule?: (order: Order | null) => void;
  onCancelSchedule?: (id: string) => void;
  productInfos: {
    productName: string,
    cpf: string;
    cnpj: string;
    email: string;
  }
}

const ScheduleMade = ({ scheduleInfos, productInfos, order, onSchedule, onCancelSchedule }: ScheduleMadeProps) => {
  const detailsLocale = scheduleInfos?.detalhePosto;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);

  const { isCancel, handleCancelClick } = useCancelSchedule({ onSchedule, onCancelSchedule });

  const handleReschedule = () => {
    handleCancelClick(scheduleInfos.pedido.toString(), 'reschedule', [order]);

    if (order?.id) {
      setTimeout(() => {
        window.location.href = `?order_id=${scheduleInfos.pedidoEcommerce}`;
      }, 1500);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  return (
    <ContainerCol>
      {isCancel && <Loading loading={isCancel} />}
      {!cancel ? (
        <>
          <Title>Agendamento realizado!</Title>
          {scheduleInfos && (
            <CardSuccess>
              <TypeValidation>
                <Paragraph>Tipo de atendimento:</Paragraph>
                <TitleValidation>{detailsLocale.videoconferencia === "true" ? 'Videoconferência online' : 'Presencial'}</TitleValidation>
              </TypeValidation>
              <Locale>
                <li>Onde: {detailsLocale.bairro} - {detailsLocale.cidade} -</li>
                <li>{detailsLocale.uf} - {detailsLocale.nomeFantasia} - {detailsLocale.endereco}, {detailsLocale.numero} - </li>
                <li>{detailsLocale.videoconferencia === "true" ? 'Videoconferência online' : 'Presencial'}</li>
              </Locale>
              <Date>
                <p>Data: {scheduleInfos.data.split('-').reverse().join('/')}</p>
              </Date>
              <Time>
                <p>Hora: {scheduleInfos.hora}</p>
              </Time>
              <Product>
                <li><ProductName>{productInfos.productName}</ProductName></li>
                {productInfos?.cpf && (
                  <li><p>CPF: {productInfos.cpf}</p></li>
                )}
                {productInfos?.cnpj && (
                  <li><p>CNPJ: {productInfos.cnpj}</p></li>
                )}
              </Product>
              <ContentButtons>
                <Button onClick={handleReschedule}>Reagendar</Button>
                <Button onClick={handleOpenModal}>Cancelar agendamento</Button>
              </ContentButtons>
            </CardSuccess>
          )}
        </>
      ) : (
        <>
          <Title>Agendamento cancelado.</Title>
          <Subtitle>Um resumo do cancelamento foi enviado ao e-mail {productInfos.email ? productInfos.email : ''}.</Subtitle>
          {scheduleInfos && (
            <>
              <CardCancelled>
                <ColumnIcon>
                  <Icon src={iconCheck} alt='Icon Check' />
                </ColumnIcon>
                <ColumnText>
                  <TextTitle>Seu agendamento foi cancelado.</TextTitle>
                  <ParagraphCancelled>Não esqueça que o agendamento é obrigatório. Você pode reagendar a qualquer momento.</ParagraphCancelled>
                </ColumnText>
              </CardCancelled>
              <ContentButtonsCancelled>
                <ButtonCancelled onClick={handleReschedule}>Voltar para agendamento</ButtonCancelled>
              </ContentButtonsCancelled>
            </>
          )}
        </>
      )}
      {openModal &&
        <ModalCancelSchedule
          orderCommerce={scheduleInfos.pedido.toString()}
          isOpen={openModal}
          setOpenModal={setOpenModal}
          order={order}
          setCancel={setCancel}
        />
      }
    </ContainerCol>
  )
}

export default ScheduleMade;