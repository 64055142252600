import { useEffect, useState } from 'react';

interface SizeProps {
  width: number;
  height: number;
}

const getViewportSize = (): SizeProps => {
  if (window.visualViewport) {
    return {
      width: window.visualViewport.width,
      height: window.visualViewport.height,
    };
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const useWindowSize = (): SizeProps => {
  const [windowSize, setWindowSize] = useState<SizeProps>(() => getViewportSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getViewportSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};