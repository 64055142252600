import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1295px;
  /* min-height: 100vh; */
  margin: 48px auto 0;

  @media screen and (max-width: 1294px) {
    padding: 0 20px;
  }
`

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;

  &.wordBreak {
    width: 75%;
  }
`