import { useState } from 'react';
import { cancelScheduling } from '../services/api';
import { Order } from '../types/types';

interface UseCancelOrderParams {
  onSchedule: (order: Order | null) => void;
  onCancelSchedule: (id: string) => void;
}

export const useCancelSchedule = ({ onSchedule, onCancelSchedule }: UseCancelOrderParams) => {
  const [isCancel, setIsCancel] = useState<boolean>(false);

  const handleCancelClick = async (id: string, action: 'reschedule' | 'cancel', orders: Order[]) => {
    setIsCancel(true);

    try {
      const response = await cancelScheduling.get('/', { params: { idGar: id } });

      if (response.status === 200) {
        if (action === 'reschedule') {
          const order = orders.find((order) => order.id === id);
          if (order) {
            onSchedule(order);
          }
        } else if (action === 'cancel') {
          onCancelSchedule(id);
        }
      } else if (response.data.codigo === 902) {
        alert(response.data.mensagem);
      }
    } catch (error) {
      console.error('Erro ao cancelar agendamento:', error);
    } finally {
      setIsCancel(false);
    }
  };

  return { isCancel, handleCancelClick };
};
