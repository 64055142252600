import { useCancelSchedule } from '../../../hooks/useCancelSchedule';
import { Order } from '../../../types/types';
import Loading from '../../Loading';
import { ContainerFixed, ContentPopUp, ButtonClose, Card, Title, TextCard, ContentButtons, ButtonConsent } from './styles';

interface ModalCancelScheduleProps {
  order: Order | null;
  isOpen: boolean;
  orderCommerce?: string;
  setOpenModal: React.Dispatch<boolean>;
  setCancel: React.Dispatch<boolean>;
  onSchedule?: (order: Order | null) => void;
  onCancelSchedule?: (id: string) => void;
}

const ModalCancelSchedule = ({ isOpen, setOpenModal, order, orderCommerce, onSchedule, onCancelSchedule, setCancel }: ModalCancelScheduleProps) => {
  const { isCancel, handleCancelClick } = useCancelSchedule({ onSchedule, onCancelSchedule });

  const handleCloseModal = () => {
    setOpenModal(!isOpen);
  }

  const handleCancelScheduling = () => {
    handleCancelClick(orderCommerce, 'cancel', [order]);
    setTimeout(() => {
      setCancel(true);
      setOpenModal(!isOpen);
    }, 1500);
  }

  return (
    <ContainerFixed>
      {isCancel && <Loading loading={isCancel} />}
      <ContentPopUp>
        <Card>
          <Title>Cancelar agendamento</Title>
          <TextCard>Você deseja realmente cancelar o agendamento deste certificado?</TextCard>
          <ContentButtons>
            <ButtonClose onClick={handleCloseModal}>Não</ButtonClose>
            <ButtonConsent onClick={handleCancelScheduling}>Sim</ButtonConsent>
          </ContentButtons>
        </Card>
      </ContentPopUp>
    </ContainerFixed>
  )
}

export default ModalCancelSchedule;