import styled from 'styled-components';

interface Props {
	$isActive: boolean;
}

export const CalendarContent = styled.div`
	.react-calendar {
		padding: 10px 20px;
		border-radius: 8px;
		border: none;
		-webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		transition: .2s;

		.react-calendar__navigation__prev2-button,
		.react-calendar__navigation__next2-button {
			display: none;
		}

		.react-calendar__navigation > button {
			color: var(--ins-blue) !important;
			font-size: 25px;
		}

		.react-calendar__navigation__label__labelText {
			font-size: 20px;
		}

		.react-calendar__month-view__weekdays__weekday {
			color: var(--ins-blue);

			abbr {
				text-decoration: none !important;
			}
		}

		.react-calendar__month-view__days__day:not(
				.react-calendar__month-view__days__day.available,
				.react-calendar__month-view__days__day--weekend.available
			) {
			pointer-events: none;
			background: var(--gray-50);
			color: var(--platform-orangeDark);
			opacity: .3;
		}

		.react-calendar__month-view__days__day--weekend {
			&:not(.available) {
				background: var(--gray-50) !important;
			}
			
			color: var(--platform-orangeDark);

			&.available {
				color: initial;

				&:hover {
					background-color: var(--gray-200) !important;
				}
			}
		}

		.react-calendar__tile {
			border-radius: 4px;

			&--now {
				background: var(--gray-200);
			}

			&:hover {
				background-color: var(--gray-200) !important;
			}

			&--active {
				background: var(--ins-blue) !important;
				color: var(--white) !important;

				&:hover {
					background: var(--ins-blue) !important;
				}
			}
		}
	}
`;

export const ContentCol = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto 30px;

	@media screen and (max-width: 767px) {
		max-width: 100%;
	}
`;

export const TitleTime = styled.h4`
	font-size: 16px;
	font-weight: 400;
	margin: 20px 0 10px 0;
`;
export const Select = styled.div<Props>`
	display: flex;
	flex-direction: column;
	position: relative;
	height: ${({ $isActive }) => ($isActive ? '200px' : '56px')};
	width: 100%;
	max-width: 389px;
	background: var(--white);
	border: 1px solid var(--gray-400);
	border-radius: 4px;
	overflow: hidden;

	> button:nth-child(1) {
		font-size: 16px;
		color: var(--gray-600);
	}

	&::after {
		content: '';
		position: absolute;
		width: 7px;
		height: 7px;
		top: 20px;
		right: 16px;
		border-top: 1px solid var(--gray-800);
		border-right: 1px solid var(--gray-800);
		transform: rotate(135deg);
	}
`;
export const ContentTime = styled.div<Props>`
	display: flex;
	flex-direction: column;
	overflow: ${({ $isActive }) => ($isActive ? 'auto' : 'hidden')};
	
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-600);
		height: 108px;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--gray-200);
	}
`;

export const TimeSlot = styled.button`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 15px;
	border: none;
	width: 100%;
	min-height: 56px;
	cursor: pointer;
	background: var(--white);
`;

// Mobile

export const ContainerData = styled.div<Props>`
	width: 100%;
	height: ${({ $isActive }) => $isActive ? '100%' : '67px'};
	overflow: hidden;
	border-radius: 8px;
	background-color: var(--gray-50);
	box-shadow: 0px 1px 3.7px 0px rgba(0, 0, 0, 0.2);
	transition: .3s;
	margin-top: 15px;
`

export const ContainerDataCollapse = styled.div`
	width: 100%;
	background-color: var(--gray-50);
	padding: 20px 10px 10px;
`

export const ButtonData = styled.button<Props>`
	max-width: 146px;
	padding-left: 20px;
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: transparent;
	border: none;
	border-bottom: ${({ $isActive }) =>
		$isActive ? '2px solid var(--ins-blue)' : 'none'};;
`

export const ContainerTime = styled.div<Props>`
	width: 100%;
	height: ${({ $isActive }) => $isActive ? '100%' : '67px'};
	overflow: hidden;
	border-radius: 8px;
	background-color: var(--gray-50);
	box-shadow: 0px 1px 3.7px 0px rgba(0, 0, 0, 0.2);
	transition: .3s;
	margin-top: 15px;
`

export const ContainerTimeCollapse = styled.div`
	width: 100%;
	background-color: var(--gray-50);
	padding: 20px 10px 10px;
`

export const ButtonTime = styled.button<Props>`
	max-width: 146px;
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px;
	background: transparent;
	border: none;
	border-bottom: ${({ $isActive }) =>
		$isActive ? '2px solid var(--ins-blue)' : 'none'};;
`