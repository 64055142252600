import LoadingImg from '../../assets/icons/loading.svg'
import { Container } from './styles'
import { ImageWithLoading } from '../../styles/global'

interface LoadingProps {
  loading?: boolean
}

const Loading = ({loading}: LoadingProps) => {
  return (
    <Container>
      {loading ? <ImageWithLoading src={LoadingImg} alt='Loading' /> : null}
    </Container>
  )
}

export default Loading;