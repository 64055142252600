/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useFetchOrder } from '../../hooks/useFetchOrder';
import { GetOrders } from '../../components/GetOrders';
import { SelectOrder } from '../../components/SelectOrder';
import { ValidationService } from '../../components/ValidationService';
import { Order } from '../../types/types';
import Loading from '../../components/Loading';
import { Container } from './styles';

export const Scheduling = () => {
  const { order, loading, error, statuses, fetchOrder, setStatuses } = useFetchOrder(); // Inclui `setStatuses`
  const [step, setStep] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [urlHasOrderId, setUrlHasOrderId] = useState<boolean>(false);
  const [errorGeneric, setError] = useState<string>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    if (orderId) {
      setUrlHasOrderId(true);
      fetchOrder(orderId);
    }
  }, []);

  useEffect(() => {
    if (order && statuses && order.length === Object.keys(statuses).length) {
      if (order[0].codigo === 265) {
        setError('Erro ao buscar pedidos. Tente novamente.');
        return;
      }
      setStep(2);
      setUrlHasOrderId(false);
    }
  }, [order, statuses]);

  const handleSchedule = (selectedOrder: Order | null) => {
    if (selectedOrder) {
      setSelectedOrder(selectedOrder);
      setStep(3);
    } else {
      console.error('Nenhum pedido selecionado.');
    }
  };

  const handleCancelSchedule = (orderId: string) => {
    const updatedStatuses = { ...statuses, [orderId]: false }; // Atualiza o status
    setStatuses(updatedStatuses); // Propaga a atualização para o estado global
  };

  const renderStep = () => {
    if (urlHasOrderId && loading) {
      return <Loading loading={loading} />;
    }

    switch (step) {
      case 1:
        return <GetOrders fetchOrder={fetchOrder} order={order} loading={loading} error={error || errorGeneric} />;
      case 2:
        return (
          <SelectOrder
            orders={order ? [order] : []}
            statuses={statuses}
            onSchedule={handleSchedule}
            onCancelSchedule={handleCancelSchedule}
          />
        );
      case 3:
        return <ValidationService order={selectedOrder} />;
      default:
        return null;
    }
  };

  return <Container>{renderStep()}</Container>;
};
