import styled from 'styled-components';

export const ButtonSend = styled.button`
	width: 100%;
	height: 42px;
	background-color: var(--ins-blue);
	color: var(--white);
	font-size: 16px;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 30px;
	transition: 0.3s;

	&:hover {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
	}

	&[disabled] {
		background-color: var(--gray-500);
		color: var(--gray-900);

		&:hover {
			border: none;
		}
	}
`;

export const Paragraph = styled.p`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 30px;
`;