// hooks/useEcommerceOrder.ts
import { useState, useEffect } from 'react';
import { idGar } from '../services/api';

export const useEcommerceOrder = (selectedOrder: string | null, setData: Function) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedOrder) return;

    const getEcommerceOrder = async () => {
      setLoading(true);
      try {
        const response = await idGar.get('/', { params: { idGar: selectedOrder } });
        setData((prevState: any) => ({
          ...prevState,
          pedidoEcommerce: response.data?.pedidoEcommerce ?? '',
        }));
      } catch (err) {
        setError('Erro ao buscar o pedido');
      } finally {
        setLoading(false);
      }
    };

    getEcommerceOrder();
  }, [selectedOrder, setData]);

  return { loading, error };
};
