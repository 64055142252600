import styled from 'styled-components';

interface Props {
	$isError: boolean;
}

export const StateCityNeightbornContent = styled.form`
	display: flex;
	align-items: flex-end;

	.form-group:not(:last-of-type) {
		margin-right: 16px;
	}

	label {
		display: block;
		margin-bottom: 8px;
	}

	fieldset {
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}

	input {
		border: 1px solid var(--gray-800);
		width: 203px;
		height: 42px;
		border-radius: 4px;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 203px;
		height: 42px;
		border: 1px solid var(--ins-blue);
		border-radius: 8px;
		background-color: transparent;
		margin-left: 16px;
		color: var(--ins-blue);
		font-weight: 700;
		transition: 0.3s;

		svg {
			margin-right: 8px;
		}

		&:hover {
			background-color: var(--ins-blue);
			color: var(--white);

			svg {
				path {
					fill: var(--white);
				}
			}
		}
	}
`;

export const Select = styled.select<Props>`
	width: 225px;
	height: 42px;
	border: ${({$isError}) => ($isError ? '1px solid var(--feedback-negative)' : '1px solid var(--gray-400)')};
	border-radius: 4px;
`;
