import { useEffect, useMemo, useState } from 'react';
import { Title, ContainerCol, Content, ContentCol, ButtonSearchType, LocalesList, LocalesListItem, ContainerCep, ContainerCepCollapse, ButtonCEP } from './styles';
import PostalCodeForm from '../../PostalCodeForm';
import usePostalCodeResult from '../../../../hooks/usePotalCodeResult';
import { Order } from '../../../../types/types';
import ScheduleCalendar from '../../ScheduleCalendar';
import { useSchedule } from '../../../../context/ScheduleContext'
import Loading from '../../../Loading';
import { useWindowSize } from '../../../../hooks/useDevice';
import StateCityNeightborn from '../../StateCityNeightborn';

interface ScheduleLocalesProps {
  order: Order | null;
  validationType: string
  isVideoConference: string;
}

const ScheduleLocales = ({ order, validationType, isVideoConference }: ScheduleLocalesProps) => {
  const [searchType, setSearchType] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [local, setLocal] = useState<number>();
  const { locales, isLoading, error, handlePostalCodeSearch } = usePostalCodeResult(postalCode, order?.id);
  const [openPostalCode, setOpenPostalCode] = useState<boolean>(true);
  const [shouldFetchSchedules, setShouldFetchSchedules] = useState(false);
  const { setData } = useSchedule();

  const { width } = useWindowSize();
  const isDevice = Number(width) < 980


  const handleSearchType = (type: string) => {
    setSearchType(type);
  };

  const handleLocaleSelected = (type: number) => {
    setLocal(type);
  };

  const localesFiltered = locales.filter(locale => {
    if (isVideoConference === 'videoconference') {
      return locale.videoconferencia === 'true';
    }
    if (isVideoConference === 'presential') {
      return locale.videoconferencia === 'false';
    }
    return false
  });

  const memoizedLocalesFiltered = useMemo(() => localesFiltered, [localesFiltered]);
  const memoizedOrder = useMemo(() => order, [order]);

  useEffect(() => {
    if (local && localesFiltered) {
      const selectedLocale = localesFiltered.find(locale => locale.codigo === local);

      if (selectedLocale) {
        setData(prevState => {
          if (
            prevState.idGar === order?.id &&
            prevState.codigo === selectedLocale.codigo
          ) {
            return prevState; // Não atualiza o estado se os valores forem iguais
          }

          return {
            ...prevState,
            idGar: order?.id,
            codigo: selectedLocale.codigo,
            nomeFantasia: selectedLocale.nomeFantasia,
            telefone: selectedLocale.telefone,
            endereco: selectedLocale.endereco,
            numero: selectedLocale.numero,
            complemento: selectedLocale.complemento || '',
            cidade: selectedLocale.cidade,
            uf: selectedLocale.uf,
            cep: selectedLocale.cep,
            bairro: selectedLocale.bairro,
          };
        });
      }
    }
  }, [local, localesFiltered, memoizedLocalesFiltered, memoizedOrder, order?.id, setData]);

  const handleOpenPostalCode = () => {
    setOpenPostalCode(!openPostalCode);
  }

  useEffect(() => {
    if (shouldFetchSchedules && postalCode) {
      handlePostalCodeSearch();
      setShouldFetchSchedules(false); // Reseta o estado para evitar chamadas repetidas
    }
  }, [postalCode, shouldFetchSchedules, handlePostalCodeSearch]);

  return (
    <>
      {!isDevice ? (
        <ContainerCol>
          <Title>Informe sua localização para acessar as agendas</Title>
          <Content className='container-top'>
            <ButtonSearchType
              $isActive={searchType === 'postalCode' || searchType === ''}
              onClick={() => handleSearchType('postalCode')}>
              Buscar por CEP
            </ButtonSearchType>
            <ButtonSearchType
              $isActive={searchType === 'city'}
              onClick={() => handleSearchType('city')}>
              Buscar por cidade
            </ButtonSearchType>
          </Content>
          <ContentCol>
            {(searchType === 'postalCode' || searchType === '') && (
              <PostalCodeForm
                postalCode={postalCode}
                error={error}
                handlePostalCodeChange={setPostalCode}
                handlePostalCodeSearch={handlePostalCodeSearch}
              />
            )}
            {(searchType === 'city') && (
              <StateCityNeightborn 
                handlePostalCodeChange={setPostalCode}
                onSetShouldFetchSchedules={setShouldFetchSchedules}
              />
            )}
          </ContentCol>
          <Content>
            {isLoading && <Loading loading={isLoading} />}
            {!error && localesFiltered && (
              <LocalesList>
                {localesFiltered
                  .sort((a, b) => a.distance - b.distance)
                  .map((locale) => (
                    <LocalesListItem key={locale.codigo} $isSelected={locale.codigo === local} onClick={() => { handleLocaleSelected(locale.codigo) }}>
                      <p>{validationType === 'videoconference' ? 'Videoconferência' : 'Presencial'} - {locale.bairro} - {locale.cidade} - {locale.uf} - {locale.nomeFantasia} - {locale.endereco}, {locale.numero} - <b>{Number(locale.distance).toFixed(2)} km</b></p>
                    </LocalesListItem>
                  ))}
              </LocalesList>
            )}
            {local && (
              <ScheduleCalendar error={error} orderId={order?.id} localCode={local} />
            )}
          </Content>
        </ContainerCol>
      ) : (
        <ContainerCol>
          <Title>Informe sua localização para acessar as agendas</Title>
          <ContainerCep $isActive={openPostalCode}>
            <ButtonCEP onClick={handleOpenPostalCode} $isActive={openPostalCode}>Busca por CEP</ButtonCEP>
            <ContainerCepCollapse>
              <PostalCodeForm
                postalCode={postalCode}
                error={error}
                handlePostalCodeChange={setPostalCode}
                handlePostalCodeSearch={handlePostalCodeSearch}
              />
              {isLoading && <Loading loading={isLoading} />}
              {!error && localesFiltered && (
                <LocalesList>
                  {localesFiltered
                    .sort((a, b) => a.distance - b.distance)
                    .map((locale) => (
                      <LocalesListItem key={locale.codigo} $isSelected={locale.codigo === local} onClick={() => { handleLocaleSelected(locale.codigo) }}>
                        <p>{validationType === 'videoconference' ? 'Videoconferência' : 'Presencial'} - {locale.bairro} - {locale.cidade} - {locale.uf} - {locale.nomeFantasia} - {locale.endereco}, {locale.numero}</p>
                      </LocalesListItem>
                    ))}
                </LocalesList>
              )}
            </ContainerCepCollapse>
          </ContainerCep>
          {local && (
            <ScheduleCalendar error={error} orderId={order?.id} localCode={local} />
          )}
        </ContainerCol>
      )}
    </>
  );
};

export default ScheduleLocales;
