import { useState, useEffect } from 'react';

interface UseFormatedCNPJProps {
  cnpj: string | undefined;
}

const formatCNPJ = (cnpj: string | undefined) => {
  if (!cnpj) return '';

  cnpj = cnpj.replace(/\D/g, '');

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

const useFormattedCNPJ = ({cnpj}: UseFormatedCNPJProps) => {
  const [formattedCNPJ, setFormattedCNPJ] = useState('');

  useEffect(() => {
    setFormattedCNPJ(formatCNPJ(cnpj));
  }, [cnpj]);

  return formattedCNPJ;
};

export default useFormattedCNPJ;