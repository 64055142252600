/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { StateCityNeightbornContent, Select } from './styles';
import { geoLocation } from '../../../services/api';

interface StateCityNeightbornProps {
  handlePostalCodeChange: (value: string) => void;
  onSetShouldFetchSchedules: (value: boolean) => void;
}
const StateCityNeightborn = ({ handlePostalCodeChange, onSetShouldFetchSchedules }: StateCityNeightbornProps) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [cep, setCep] = useState('');

  useEffect(() => {
    const fetchStates = async () => {
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      const data = response.data;
      const formattedStates = data
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .map((state) => ({
          code: state.sigla,
          name: state.nome,
        }));
      setStates(formattedStates);
    };
    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState) {
      setSelectedCity('');
      setSelectedNeighborhood('');
      const fetchCities = async () => {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`)
        const data = response.data;
        const formattedCities = data.map((city) => ({
          name: city.nome,
          code: city.id,
        }));
        setCities(formattedCities);
      }
      fetchCities();
    } else {
      setCities([]);
      setSelectedCity('');
      setSelectedNeighborhood('');
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity && cities.length > 0) {
      const cityName = cities.find(city => city.code === parseInt(selectedCity))?.name;
      if (cityName) {
        fetchNeighborhoods(cityName);
      }
    }
  }, [selectedCity, cities]);

  const fetchNeighborhoods = async (cityName) => {
    try {
      const response = await geoLocation.get(`/textsearch?query=bairros+de+${encodeURIComponent(cityName)}`);
      const data = response.data;
      if (data.status === "OK") {
        const neighborhoods = data.results
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((place) => ({
            name: place.name,
            id: place.place_id,
          }));
        setNeighborhoods(neighborhoods);
      }
    } catch (error) {
      console.log('Erro ao buscar bairros:', error);
      setNeighborhoods([]);
    }
  };

  const fetchCEP = async () => {
    const cityName = cities.find(city => city.code === parseInt(selectedCity))?.name;
    const neighborhoodName = neighborhoods.find(neigh => neigh.id === selectedNeighborhood)?.name;
    const address = `${neighborhoodName}, ${cityName}, ${selectedState}, Brazil`;
    try {
      const geocodeResponse = await geoLocation.get(`/geocode/json?address=${encodeURIComponent(address)}`);
      const geoCodeData = geocodeResponse.data;

      if (geoCodeData.status === 'OK' && geoCodeData.results.length > 0) {
        const { lat, lng } = geoCodeData.results[0].geometry.location;
        const reverseGeocodeResponse = await geoLocation.get(`/geocode/json?latlng=${lat},${lng}`);
        const reverseGeocodeData = await reverseGeocodeResponse.data;

        if (reverseGeocodeData.status === 'OK' && reverseGeocodeData.results.length > 0) {
          const addressComponents = reverseGeocodeData.results[0].address_components;
          const cepComponent = addressComponents.find(component => component.types.includes('postal_code'));

          if (cepComponent) {
            const cep = cepComponent.long_name;
            const formattedCEP = cep.length === 5 ? `${cep}-000` : cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
            setCep(formattedCEP);
            return formattedCEP;
          }
        }
      }
      setCep('Erro ao encontrar CEP');
      return '';
    } catch (error) {
      console.error('Erro ao obter as coordenadas geográficas.', error);
      setCep('Erro ao encontrar CEP');
      return '';
    }
  };

  const handleSearch = async () => {

    if (selectedState === '') {
      setError(true);
      return;
    }

    if (selectedCity === '') {
      setError(true);
      return;
    }

    if (selectedNeighborhood === '') {
      setError(true);
      return;
    }

    setError(false);
    const updatedCep = await fetchCEP();
    if (updatedCep) {
      handlePostalCodeChange(updatedCep);
      onSetShouldFetchSchedules(true);
    }
  };

  return (
    <StateCityNeightbornContent>
      <div className='form-group'>
        <label htmlFor='UF'>Estado</label>
        <Select $isError={error && selectedState === ''} id='UF' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
          <option value="">Selecione o estado</option>
          {states.map((state) => (
            <option key={state.code} value={state.code}>
              {state.name}
            </option>
          ))}
        </Select>
      </div>
      <div className='form-group'>
        <label htmlFor='city'>Cidade</label>
        <Select $isError={error && selectedCity === ''}
          id='city'
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
        >
          <option value="">Selecione a cidade</option>
          {cities.map((city) => (
            <option key={city.code} value={city.code}>
              {city.name}
            </option>
          ))}
        </Select>
      </div>
      <div className='form-group'>
        <label htmlFor='neighborhood'>Bairro</label>
        <Select $isError={error && selectedNeighborhood === ''} id='neighborhood' value={selectedNeighborhood} onChange={(e) => setSelectedNeighborhood(e.target.value)}>
          <option value="">Selecione o bairro</option>
          {neighborhoods.map((neighborhood) => (
            <option key={neighborhood.id} value={neighborhood.id}>
              {neighborhood.name}
            </option>
          ))}
        </Select>
      </div>
      <button type='button' onClick={handleSearch}>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5 6.5C13.5 7.93437 13.0344 9.25938 12.25 10.3344L16.2063 14.2937C16.5969 14.6844 16.5969 15.3188 16.2063 15.7094C15.8156 16.1 15.1812 16.1 14.7906 15.7094L10.8344 11.75C9.75938 12.5375 8.43437 13 7 13C3.40937 13 0.5 10.0906 0.5 6.5C0.5 2.90937 3.40937 0 7 0C10.5906 0 13.5 2.90937 13.5 6.5ZM7 11C7.59095 11 8.17611 10.8836 8.72208 10.6575C9.26804 10.4313 9.76412 10.0998 10.182 9.68198C10.5998 9.26412 10.9313 8.76804 11.1575 8.22208C11.3836 7.67611 11.5 7.09095 11.5 6.5C11.5 5.90905 11.3836 5.32389 11.1575 4.77792C10.9313 4.23196 10.5998 3.73588 10.182 3.31802C9.76412 2.90016 9.26804 2.56869 8.72208 2.34254C8.17611 2.1164 7.59095 2 7 2C6.40905 2 5.82389 2.1164 5.27792 2.34254C4.73196 2.56869 4.23588 2.90016 3.81802 3.31802C3.40016 3.73588 3.06869 4.23196 2.84254 4.77792C2.6164 5.32389 2.5 5.90905 2.5 6.5C2.5 7.09095 2.6164 7.67611 2.84254 8.22208C3.06869 8.76804 3.40016 9.26412 3.81802 9.68198C4.23588 10.0998 4.73196 10.4313 5.27792 10.6575C5.82389 10.8836 6.40905 11 7 11Z" fill="#593EFF" />
        </svg>
        Buscar agendas
      </button>
    </StateCityNeightbornContent>
  );
}

export default StateCityNeightborn;