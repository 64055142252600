import { useState } from 'react';  

const useFormatter = () => {  
  const [value, setValue] = useState('');

  const formatInput = (input: string) => {
    const onlyNumbers = input.replace(/\D/g, '');

    let formattedValue = '';  

    if (onlyNumbers.length <= 11) {
      if (onlyNumbers.length > 0)
        formattedValue = onlyNumbers.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    } else if (onlyNumbers.length <= 14) {
      if (onlyNumbers.length > 0)
        formattedValue = onlyNumbers.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }

    return formattedValue;
  };

  const handleChange = (input: string) => {
    const formattedValue = formatInput(input);
    setValue(formattedValue);
  };

  return [value, handleChange] as const;
};

export default useFormatter;