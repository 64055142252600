import videoconferencia from '../../../../assets/icons/videoconferencia.svg'
import presencial from '../../../../assets/icons/presencial.svg'
import Card from '../../../Card';
import { TitlePage, Text, Content, ContainerCol } from './styles';

interface ScheduleTypeProps {
  onPopVideoConference: () => void;
  onPresential: () => void;
}

const ScheduleType = ({ onPopVideoConference, onPresential }: ScheduleTypeProps) => {
  return (
    <ContainerCol>
      <TitlePage>Como você quer agendar o atendimento da validação?</TitlePage>
      <Text>O agendamento é uma etapa obrigatória para confirmar a identidade de quem comprou o certificado digital e para instalá-lo depois. </Text>
      <Content>
        <Card
          icon={videoconferencia}
          title={'Videoconferência'}
          textTop={'Atendimento realizado online, com data e hora marcada e exclusivo ao cliente que:'}
          textInfo={'Possui CNH ou que já emitiu certificado digital com a Certisign ou com alguma outra certificadora.'}
          textBottom={'Os documentos obrigatórios precisam ser enviados previamente por e-mail.'}
          buttonText="Videoconferência"
          onClick={onPopVideoConference} />
        <Card
          icon={presencial}
          title="Presencial"
          textTop="Atendimento realizado presencialmente, com data e hora marcada em um de nossos pontos de atendimento. Disponível para qualquer cliente."
          textBottom='Os documentos obrigatórios são apresentados na hora, sem a necessidade de envio prévio.'
          buttonText="Presencial"
          onClick={onPresential}
        />
      </Content>
    </ContainerCol>
  )
}

export default ScheduleType;