import { useState, Fragment } from 'react';
import {
  Container, Card, Form, Icon, IconContent, Subtitle, Title, TitlePage, Paragraph, ContentCol
} from './styles';
import cart from '../../assets/icons/cart.svg';
import user from '../../assets/icons/user.svg';
import LoadingImg from '../../assets/icons/loading.svg';
import { ImageWithLoading } from '../../styles/global';
import useFormatter from '../../hooks/useFormatter';
import useOnlyNumbers from '../../hooks/useOnlyNumbers';

export const GetOrders = ({ fetchOrder, loading, order, error }) => {
  const { garOrder, onlyNumber } = useOnlyNumbers();
  const [value, handleChange] = useFormatter();

  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);

  const handleSearch = async (event: React.FormEvent, field: 'garOrder' | 'document') => {
    event.preventDefault();
    const searchValue = (garOrder || value).replace(/\D/g, '');

    if (field === 'garOrder') {
      setLoadingOrder(true);
    } else {
      setLoadingDocument(true);
    }

    try {
      await fetchOrder(searchValue);
    } finally {
      if (field === 'garOrder') {
        setLoadingOrder(false);
      } else {
        setLoadingDocument(false);
      }
    }
  };

  const isGarOrderValid = garOrder !== '';
  const isDocumentValid = value !== '';

  return (
    <Fragment>
      <Container>
        <TitlePage>Agendamento da validação</TitlePage>
        <Paragraph className='wordBreak'>A validação é uma <strong>etapa obrigatória</strong>, porque é o momento em que a identidade do titular do certificado digital é confirmada. Somente após esse atendimento, o produto é entregue para a instalação.</Paragraph>
        <Paragraph>Para agendar a validação é necessário informar o <strong>número do pedido</strong> ou o <strong>CPF</strong> ou <strong>CNPJ</strong> do titular do certificado digital.</Paragraph>
      </Container>
      <Container className='row'>
        <Card>
          <IconContent>
            <Icon src={cart} alt='Icone' />
          </IconContent>
          <Title>Agendar pelo número do pedido</Title>
          <Subtitle>O número do pedido possui 8 dígitos.</Subtitle>
          <Form onSubmit={(e) => handleSearch(e, 'garOrder')}>
            <input
              onChange={onlyNumber}
              type='text'
              placeholder='Digite o número do pedido'
              maxLength={8}
              value={garOrder}
            />
            <button
              disabled={!isGarOrderValid || loading}
              type="submit"
            >
              {loadingOrder ? <ImageWithLoading src={LoadingImg} alt="Carregando" /> : 'Agendar'}
            </button>
          </Form>
        </Card>
        <Card>
          <IconContent>
            <Icon src={user} alt='Icone' />
          </IconContent>
          <Title>Agendar com o CPF ou CNPJ do titular</Title>
          <Subtitle>Digite somente os números</Subtitle>
          <Form onSubmit={(e) => handleSearch(e, 'document')}>
            <input
              onChange={(e) => handleChange(e.target.value)}
              type='text'
              placeholder='Digite CPF ou CNPJ do titular'
              maxLength={18}
              value={value}
            />
            <button
              disabled={!isDocumentValid || loading}
              type="submit"
            >
              {loadingDocument ? <ImageWithLoading src={LoadingImg} alt="Carregando" /> : 'Agendar'}
            </button>
          </Form>
        </Card>
      </Container>
      <ContentCol>
        <Paragraph $isError={!!error}>{error}</Paragraph>
      </ContentCol>
    </Fragment>
  );
};






