import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1295px;
	margin: 48px auto 0;
	position: relative;
	display: flex;
	justify-content: space-between;

	@media (max-width: 1295px) {
		max-width: 100%;
		flex-direction: column;
	}
`;

export const ContainerCol = styled.div`
	display: flex;
	flex-direction: column;
`

export const Aside = styled.aside`
	max-width: 312px;
	width: 100%;
	border-radius: 8px;
	background-color: var(--white);
	padding: 30px 20px 30px 13px;
	z-index: 1;

	@media (max-width: 1295px) {
		display: none;
	}
`;

export const ButtonValidation = styled.button`
	width: 100%;
	height: 42px;
	background-color: transparent;
	color: var(--ins-blue);
	font-size: 16px;
	font-weight: 700;
	border: 1px solid var(--ins-blue);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 30px;
	transition: 0.3s;

	&:hover {
		background-color: var(--ins-blue);
		color: var(--white);
	}
`;

export const Subtitle = styled.h3`
	font-size: 24px;
	font-weight: 400;
	color: var(--ins-blue);
`;

export const Paragraph = styled.p`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 30px;

	&.validationType {
		margin-bottom: 10px;
	}
`;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const ListItem = styled.li`
	margin-bottom: 20px;
	color: var(--gray-800);
	position: relative;
`;

export const Image = styled.img`
	position: absolute;
	top: 100px;
	left: 17%;
`;
