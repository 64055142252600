import { useEffect, useState } from 'react'
import ScheduleType from './Steps/ScheduleType';
import ScheduleLocales from './Steps/ScheduleLocales';
import PopUp from './PopUp'
import useFormattedCPF from '../../hooks/useFormatedCpf';
import useFormattedCNPJ from '../../hooks/useFormattedCNPJ';
import { Order, ScheduleDetails } from '../../types/types';
import ButtonSendData from './ButtonSendData';
import ScheduleMade from './ScheduleMade';
import { useSchedule } from '../../context/ScheduleContext';
import { useWindowSize } from '../../hooks/useDevice';
import {
  Aside,
  Container,
  ButtonValidation,
  Subtitle,
  Paragraph,
  List,
  ListItem,
} from './styles'
interface ValidationServiceProps {
  order: Order | null;
}

export const ValidationService = ({ order }: ValidationServiceProps) => {
  const FormattedCpf = useFormattedCPF({ cpf: order?.cpf });
  const FormattedCnpj = useFormattedCNPJ({ cnpj: order?.historicoPessoaJuridica?.cnpj });
  const [scheduleInfos, setScheduleInfos] = useState<ScheduleDetails>()
  const [step, setStep] = useState<number>(1);
  const [isVideoConference, setIsVideoConference] = useState(false);
  const [validationType, setValidationType] = useState('');
  const [schedule, setSchedule] = useState<boolean>(false)
  const { setData } = useSchedule();

  const { width } = useWindowSize();
  const isDevice = Number(width) < 1295;

  const ProductInfos = {
    productName: order?.produto.descricao,
    cpf: FormattedCpf,
    cnpj: FormattedCnpj,
    email: order.email
  };

  const handlePopVideoConference = () => {
    setIsVideoConference(!isVideoConference);
  }

  const handleValidationType = (type: string) => {
    setValidationType(type);
    setStep(2);
  }

  const handleSchedulingSuccess = () => {
    setStep(3);
  }

  const renderStep = () => {
    const stepComponents: { [key: number]: JSX.Element } = {
      1: <ScheduleType onPopVideoConference={handlePopVideoConference} onPresential={() => handleValidationType('presential')} />,
      2: <ScheduleLocales isVideoConference={validationType} order={order} validationType={validationType} />,
      3: <ScheduleMade
        productInfos={ProductInfos}
        scheduleInfos={scheduleInfos}
        order={order} />
    }
    return stepComponents[step] || null;
  }

  const renderValidationTypeSection = () => {
    return (
      <>
        <Paragraph className='validationType'>Tipo de atendimento:</Paragraph>
        <Subtitle>{validationType === 'videoconference' ? 'Videoconferência' : 'Presencial'}</Subtitle>
        <ButtonValidation onClick={() => { handleValidationType(''); setStep(1) }}>Alterar</ButtonValidation>
      </>
    )
  }

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      videoconferencia: validationType === 'videoconference' ? true : false
    }))
  }, [setData, validationType])

  return (
    <Container>
      {renderStep()}
      {!schedule && isDevice && (
        <>
          {validationType && (
            <ButtonSendData
              onSuccess={handleSchedulingSuccess}
              schedule={schedule}
              setSchedule={setSchedule}
              setScheduleInfos={setScheduleInfos} />
          )}
        </>
      )}
      {!schedule && (
        <Aside>
          {validationType && renderValidationTypeSection()}
          <Paragraph>
            Agendando atendimento para:
          </Paragraph>
          <List>
            <ListItem><strong>{order?.produto.descricao}</strong></ListItem>
            <ListItem>{FormattedCpf && `CPF: ${FormattedCpf}`}</ListItem>
            <ListItem>{FormattedCnpj && `CNPJ: ${FormattedCnpj}`}</ListItem>
          </List>
          {validationType && (
            <ButtonSendData
              onSuccess={handleSchedulingSuccess}
              schedule={schedule}
              setSchedule={setSchedule}
              setScheduleInfos={setScheduleInfos} />
          )}
        </Aside>
      )}
      {isVideoConference && (
        <PopUp
          handlePopVideoConference={handlePopVideoConference}
          handleValidationType={handleValidationType} />
      )}
    </Container>
  )
}