import { createContext, useState, useContext, ReactNode } from 'react';
import { ScheduleDataProps } from '../types/types';

interface ScheduleContextProps {
  data: ScheduleDataProps;
  setData: React.Dispatch<React.SetStateAction<ScheduleDataProps>>;
}

const ScheduleContext = createContext<ScheduleContextProps | undefined>(undefined);

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error('useSchedule must be used within a ScheduleProvider');
  }
  return context;
};

export const ScheduleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<ScheduleDataProps>({
    idGar: '',
    postoCodigo: null,
    pedidoEcommerce: '',
    data: '',
    hora: '',
    codigo: null,
    nomeFantasia: '',
    telefone: null,
    endereco: '',
    numero: null,
    complemento: '',
    cidade: '',
    uf: '',
    cep: null,
    bairro: '',
    videoconferencia: false,
  });

  return (
    <ScheduleContext.Provider value={{ data, setData }}>
      {children}
    </ScheduleContext.Provider>
  );
};
