import { Scheduling } from './pages/Scheduling';
import { Header } from './components/Header';
import { GlobalStyle, Main } from './styles/global';
import { ScheduleProvider } from '../src/context/ScheduleContext'
function App() {
  return (
    <ScheduleProvider>
      <Main>
        <GlobalStyle />
        <Header />
        <Scheduling />
      </Main>
    </ScheduleProvider>
  );
}

export default App;
