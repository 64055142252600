import styled from 'styled-components';

interface Props {
	$isScheduled: boolean;
}

export const Container = styled.div`
	max-width: 1295px;
	margin: 16px auto 0;

	&.content-table {
		min-height: 430px;

		@media screen and (max-height: 730px) {
			min-height: 320px;
		}

		@media screen and (max-width: 1024px) {
			max-width: 100%;
			width: 100%;
			overflow-x: auto;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 16px;

	@media screen and (max-width: 1024px) {
		&.content-buttons {
			flex-direction: column;
			align-items: flex-start;
		}
	}
`;

export const BtnContents = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const ButtonReschedule = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	border: 1px solid var(--ins-blue);
	border-radius: 8px;
	padding: 8px;
	background-color: transparent;
	font-weight: 700;
	font-size: 14px;
	color: var(--ins-blue);
	margin-right: 10px;

	&:hover {
		color: var(--white);
		background-color: var(--ins-blue);
	}
`;

export const ButtonDetails = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	border: 1px solid var(--ins-blue);
	border-radius: 8px;
	padding: 8px;
	background-color: transparent;
	font-weight: 700;
	font-size: 14px;
	color: var(--ins-blue);
	margin-right: 10px;

	&:hover {
		color: var(--white);
		background-color: var(--ins-blue);
	}
`;

export const ButtonCancel = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	border: 1px solid var(--ins-blue);
	border-radius: 8px;
	padding: 8px;
	background-color: transparent;
	font-weight: 700;
	font-size: 14px;
	color: var(--ins-blue);

	&:hover {
		color: var(--white);
		background-color: var(--ins-blue);
	}
`;

export const ContentPaginate = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 30px;
	width: 250px;

	@media screen and (max-width: 1024px) {
		margin: 20px 0;
		width: 205px;
	}
`;

export const TitlePage = styled.h1`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 20px;
`;

export const Paragraph = styled.p`
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;
	@media screen and (max-width: 1024px) {
		font-size: 14px;
	}

	&.wordBreak {
		width: 75%;

		@media screen and (max-width: 1024px) {
			width: 100%;
		}
	}
`;

export const Table = styled.table`
	max-width: 1295px;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 10px;

	.tableHead {
		min-width: 20px;
		text-align: left;
	}
`;

export const TableRow = styled.tr<Props>`
	border-radius: 8px;
	background-color: var(--white);

	&.selected {
		.tableLine {
			background-color: var(--ins-ice);

			&:first-of-type > div {
				&:after {
					content: '';
					position: absolute;
					top: 28px;
					left: 12px;
					width: 10px;
					height: 10px;
					background-color: var(--ins-blue);
					border-radius: 50%;
				}
			}
			&.tableName {
				max-width: 200px;
			}
		}
	}

	.tableLine {
		cursor: pointer;
		border-top: 1px solid var(--gray-500);
		border-bottom: 1px solid var(--gray-500);
		position: relative;

		> div {
			display: flex;
			align-items: center;
			padding: 10px 20px 10px 0;
			height: 65px;
			max-height: 65px;
			overflow: hidden;

			@media screen and (max-width: 1024px) {
				width: max-content;
			}
		}

		&:first-of-type {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-left: 1px solid var(--gray-500);
			position: relative;

			> div {
				justify-content: center;
				width: 55px;

				&::before {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					border: 1px solid var(--gray-500);
					border-radius: 50%;
				}
			}
		}

		&:last-of-type {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-right: 1px solid var(--gray-500);
		}

		&.tableName {
			max-width: 200px;

			> div {
				height: 100%;
				align-items: flex-start;
				position: relative;

				@media screen and (max-width: 1024px) {
					max-width: 200px;
				}
			}
		}
	}
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
	width: 203px;
	height: 42px;
	font-size: 18px;
	border-radius: 8px;
	border: none;
	background-color: var(--ins-blue);
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	color: var(--white);

	&[disabled] {
		cursor: auto;
		background-color: var(--gray-500);
	}
`;

export const ButtonPaginate = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--ins-blue);
	border-radius: 8px;
	border: none;
	width: auto;
	padding: 12px;
	cursor: pointer;

	&[disabled] {
		cursor: auto;
		background-color: var(--gray-500);
	}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

export const ContainerFixed = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
`;

export const ContentPopUp = styled.div`
	position: relative;
	max-width: 315px;
	width: 100%;
	height: 270px;
	background-color: var(--white);
	padding: 15px 30px;
	border-radius: 8px;

	@media screen and (max-width: 767px) {
		width: 90%;
		min-height: 270px;
	}
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
  justify-content: center;
	max-width: 100%;
	width: 100%;
  height: 100%;
	background-color: var(--white);
	line-height: 22px;
  text-align: center;
`;

export const Title = styled.h3`
  color: var(--ins-blue);
  font-weight: 600;
  margin-bottom: 16px;
`

export const TextCard = styled.p`
	color: var(--gray-900);
	font-weight: 400;
	font-size: 15px;
	padding: 0 5px;

	&.margin-top {
		margin-top: 16px;
	}
`;

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
`

export const ButtonConsent = styled.button`
	width: 150px;
	height: 44px;
	background-color: var(--ins-blue);
	color: var(--white);
	font-size: 16px;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;

	&[disabled] {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
		cursor: default;
	}

	&:hover {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
	}
`;

export const ButtonClose = styled.button`
	width: 150px;
	height: 44px;
	background-color: transparent;
	border: 1px solid var(--ins-blue);
	color: var(--ins-blue);
	font-size: 16px;
	font-weight: 700;
	border-radius: 8px;
  margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;

	&[disabled] {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
		cursor: default;
	}

	&:hover {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
	}
`;