import styled from 'styled-components';

export const CardSuccess = styled.div`
	max-width: 530px;
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 530px;
	height: 470px;
	background-color: var(--white);
	border-radius: 8px;
	padding: 32px 13px;

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
`;

export const CardCancelled = styled.div`
	max-width: 650px;
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 200px;
	background-color: var(--white);
	border: 1px solid var(--gray-300);
	border-radius: 8px;
	padding: 32px 13px;

	@media screen and (max-width: 1024px) {
		width: 100%;
		flex-direction: column;
	}
`;

export const ColumnIcon = styled.div`
	width: 200px;
	display: flex;
	justify-content: center;
`;

export const ColumnText = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Icon = styled.img`
	max-width: 100%;
	height: 100%;
	object-fit: contain;
`
export const ContainerCol = styled.div`
	display: flex;
	flex-direction: column;
	
	@media screen and (max-width: 1024px) {
		text-align: center;
	}
`;

export const Title = styled.h1`
	font-size: 32px;
	font-weight: 400;
	margin: 70px 0 32px;
`;

export const Subtitle = styled.p`
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 32px;
`;

export const TextTitle = styled.h4`
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 16px;
	color: var(--gray-800);
`

export const TypeValidation = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
`;

export const ParagraphCancelled = styled.p`
	margin-bottom: 8px;
	color: var(--gray-600);
`;

export const Paragraph = styled.p`
	margin-bottom: 8px;
	color: var(--gray-800);
`;

export const TitleValidation = styled.p`
	font-size: 24px;
	color: var(--ins-blue);
`;

export const Locale = styled.ul`
	list-style: none;
	line-height: 22px;
	margin-bottom: 16px;

	li {
		font-size: 14px;

		&:last-of-type {
			text-transform: uppercase;
		}
	}
`;

export const Date = styled.div`
	p {
		font-size: 14px;
		color: var(--gray-900);
	}
	margin-bottom: 16px;
`;

export const Time = styled.div`
	p {
		font-size: 14px;
		color: var(--gray-900);
	}
	margin-bottom: 16px;
`;

export const Product = styled.ul`
	list-style: none;
	margin-bottom: 16px;

	li {
		margin-bottom: 8px;
	}
`;

export const ProductName = styled.h3`
	font-size: 16px;
	font-weight: 700;
	color: var(--gray-800);
	max-width: 280px;
`;

export const ContentButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const ContentButtonsCancelled = styled.div`
	display: flex;
	align-items: center;
	max-width: 530px;
	width: 100%;
	margin: 16px 0;
`;

export const Button = styled.button`
	width: 100%;
	height: 42px;
	border-radius: 8px;
	border: 1px solid var(--ins-blue);
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	color: var(--ins-blue);
	margin-bottom: 16px;
	transition: 0.3s;

	&:hover {
		background-color: var(--ins-blue);
		color: var(--white);
	}
`;

export const ButtonCancelled = styled.button`
	max-width: 200px;
	width: 100%;
	height: 42px;
	border-radius: 8px;
	border: 1px solid var(--ins-blue);
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	color: var(--ins-blue);
	margin-bottom: 16px;
	transition: 0.3s;

	&:hover {
		background-color: var(--ins-blue);
		color: var(--white);
	}
`;
