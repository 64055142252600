import { useState } from 'react';  

function useOnlyNuber() {
	const [garOrder, setGarOrder] = useState('');
	function onlyNumber(e: React.ChangeEvent<HTMLInputElement>) {
		const value = e.target.value.replace(/\D/g, '');
		if (value.length <= 8) {
			setGarOrder(value);
		}
	}

	return {
		garOrder,
		onlyNumber,
	};
}

export default useOnlyNuber;