import { ContainerFixed, ContentPopUp, ButtonClose, Card, ButtonConsent, TextCard } from './styles';

interface PopUpProps {
  handleValidationType: (type: string) => void;
  handlePopVideoConference: () => void;
}

const PopUp = ({handleValidationType, handlePopVideoConference}: PopUpProps) => {
  return (
  <ContainerFixed>
    <ContentPopUp>
      <ButtonClose onClick={handlePopVideoConference}>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M18.5872 2.27315C18.9648 1.8701 18.9441 1.23727 18.5411 0.85969C18.138 0.482109 17.5052 0.502758 17.1276 0.90581L9.85742 8.66647L2.58722 0.90581C2.20963 0.502758 1.57681 0.482109 1.17375 0.85969C0.770702 1.23727 0.750054 1.8701 1.12763 2.27315L8.48717 10.1292L1.12763 17.9852C0.750054 18.3882 0.770702 19.0211 1.17375 19.3986C1.57681 19.7762 2.20963 19.7556 2.58722 19.3525L9.85742 11.5919L17.1276 19.3525C17.5052 19.7556 18.138 19.7762 18.5411 19.3986C18.9441 19.0211 18.9648 18.3882 18.5872 17.9852L11.2277 10.1292L18.5872 2.27315Z" fill="black"/>
        </svg>
      </ButtonClose>
      <Card className='fullWidth'>
        <TextCard>O atendimento por vídeoconferência é exclusivo para clientes que possuem CNH (Carteira Nacional de Habilitação) ou que já emitiram certificado digital na Certisign ou em outra certificadora.</TextCard>
        <TextCard className='margin-top'><b>Caso você não atenda aos requisitos, o seu atendimento não será realizado.</b></TextCard>
        <ButtonConsent onClick={() => {handleValidationType('videoconference'); handlePopVideoConference()}}>Ok, estou ciente</ButtonConsent>
      </Card>
    </ContentPopUp>
  </ContainerFixed>
  )
}

export default PopUp;