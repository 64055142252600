import styled from 'styled-components';

export const FormPostalCode = styled.form`
	label {
		display: block;
		margin-bottom: 8px;
	}

	fieldset {
		border: none;
		display: flex;
		align-items: center;
		margin: 0 auto;
		
		@media screen and (max-width: 1024px) {
			justify-content: space-between;
		}
	}

	input {
		border: 1px solid var(--gray-400);
		width: 203px;
		height: 42px;
		border-radius: 4px;
		outline: none;
		text-indent: 10px;

		&::placeholder {
			color: var(--gray-400);
		}

		&:focus,
		&:focus-visible,
		&:active {
			border: 1px solid var(--ins-blue);
		}

		@media screen and (max-width: 767px) {
			width: 160px;
		}
	}

	a {
		display:none;
		color: var(--ins-blue);
		text-decoration: underline;
		font-weight: 700;
		font-size: 16px;
		margin: 0 30px;

		&.desktop {
			@media screen and (min-width: 1024px) {
				display: block;
			}
		}

		&.mobile {
			@media screen and (max-width: 1024px) {
				display: block;
				margin: 10px 0 0;
			}
		}
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 203px;
		height: 42px;
		border: 1px solid var(--ins-blue);
		border-radius: 8px;
		background-color: transparent;
		color: var(--ins-blue);
		font-weight: 700;
		transition: 0.3s;

		svg {
			margin-right: 8px;
		}

		&:hover {
			background-color: var(--ins-blue);
			color: var(--white);

			svg {
				path {
					fill: var(--white);
				}
			}
		}

		@media screen and (max-width: 1024px) {
				width: 138px;
				font-size: 0;
				background-color: var(--ins-blue);
				color: var(--white);

				&::before {
					content: 'Buscar';
					font-size: 16px;
				}

				svg {
					display: none;
				}
			}
	}
`;

export const Error = styled.p`
	margin-top: 10px;
	margin-bottom: 0;
	color: var(--platform-orangeDark);
`