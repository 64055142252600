/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useState } from 'react';
import ArrowLeft from '../../assets/icons/arrowLeft.svg';
import ArrowRight from '../../assets/icons/arrowRight.svg';
import useFormattedCPF from '../../hooks/useFormatedCpf';
import useFormattedCNPJ from '../../hooks/useFormattedCNPJ';
import { DetailsProps, Order } from '../../types/types';
import { useSchedule } from '../../context/ScheduleContext'
import { useEcommerceOrder } from '../../hooks/useEcommerceOrder';
import Loading from '../Loading';
import { useCancelSchedule } from '../../hooks/useCancelSchedule';
import DetailsSchedule from '../ValidationService/DetailsSchedule';
import { cancelScheduling, schedulingStatus } from '../../services/api';
import {
  Container,
  Content,
  ContentPaginate,
  TitlePage,
  Paragraph,
  Table,
  Button,
  ButtonPaginate,
  Image,
  TableRow,
  BtnContents,
  ButtonReschedule,
  ButtonCancel,
  ButtonDetails,
  ContainerFixed,
  ContentPopUp,
  ButtonClose,
  Card,
  Title,
  TextCard,
  ContentButtons,
  ButtonConsent
} from './styles';

interface OrderProps {
  orders: Order[];
  onSchedule: (selectedOrder: Order | null) => void;
  statuses: Record<string, boolean>;
  onCancelSchedule: (id: string) => void;
}

const ITEMS_PER_PAGE = 5;

export const SelectOrder = ({ orders, onSchedule, statuses, onCancelSchedule }: OrderProps) => {
  const { isCancel, handleCancelClick } = useCancelSchedule({ onSchedule, onCancelSchedule });
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isActive, setActive] = useState<boolean>(false);
  const [scheduleDetails, setDetails] = useState<DetailsProps>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalOrderId, setModalOrderId] = useState<string | null>(null);


  const { setData } = useSchedule();
  useEcommerceOrder(selectedOrder, setData);

  const flatOrders = orders.flat();
  const totalPages = Math.ceil(flatOrders.length / ITEMS_PER_PAGE);

  const sortedOrders = flatOrders.sort((a, b) => {
    const dateA = new Date(a.data);
    const dateB = new Date(b.data);
    return dateB.getTime() - dateA.getTime();
  });

  const formattedOrders = sortedOrders.map(order => {
    const formattedCpfCnpj = order.cpf
      ? useFormattedCPF({ cpf: order.cpf })
      : useFormattedCNPJ({ cnpj: order.historicoPessoaJuridica.cnpj });

    return { ...order, formattedCpfCnpj };
  });

  const handleSelectOrder = (id: string) => {
    if (!statuses[id]) {
      setSelectedOrder(id);
    }
  }

  const handleScheduleClick = () => {
    const order = formattedOrders.find(order => order.id === selectedOrder);
    onSchedule(order || null);
  }

  const handleOpenDetails = async (orderId) => {
    setActive(!isActive);
    try {
      setLoading(true);
      const response = await schedulingStatus.get('/', { params: { idGar: orderId } });
      setDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar status dos pedidos:', error);
      setLoading(false);
    }
  }

  const handleOpenModal = (id: string) => {
    setModalOrderId(id);
    setOpenModal(true);
  }
  
  const handleCancelScheduling = async () => {
    if (!modalOrderId) return;
    try {
      setLoading(true);
      const response = await cancelScheduling.get('/', { params: { idGar: modalOrderId } });

      if (response.status === 200) {
          onCancelSchedule(modalOrderId);
          setLoading(false);
          setOpenModal(false);
          setModalOrderId('');
      } else if (response.data.codigo === 902) {
        alert(response.data.mensagem);
        setLoading(false);
        setOpenModal(false);
        setModalOrderId('');
      }
    } catch (error) {
      console.error('Erro ao cancelar agendamento:', error);
      setLoading(false);
      setOpenModal(false);
      setModalOrderId('');
    }
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const paginatedOrders = formattedOrders.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  }

  const isLastPage = currentPage === totalPages;
  const isFirstPage = currentPage === 1;

  return (
    <Fragment>
      {isCancel && <Loading loading={isCancel} />}
      {isLoading && <Loading loading={isLoading} />}
      <Container>
        <TitlePage>Agendamento da validação</TitlePage>
        <Paragraph>Selecione o produto para o qual você deseja agendar a validação.</Paragraph>
      </Container>
      <Container className='content-table'>
        <Table>
          <thead>
            <tr>
              <th className='tableHead'></th>
              <th className='tableHead'>Pedido</th>
              <th className='tableHead'>CPF/CNPJ</th>
              <th className='tableHead'>Produto</th>
              <th className='tableHead'>Titular</th>
              <th className='tableHead'></th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <TableRow
                $isScheduled={statuses[order.id]}
                className={`tableRow ${selectedOrder === order.id ? 'selected' : ''}`}
                key={order.id}
                onClick={() => handleSelectOrder(order.id)}
              >
                <td className='tableLine'><div></div></td>
                <td className='tableLine'><div>{order.id}</div></td>
                <td className='tableLine'><div>{order.formattedCpfCnpj}</div></td>
                <td className='tableLine tableName'><div>{order.produto.descricao}</div></td>
                <td className='tableLine'>
                  <div>
                    {order.historicoPessoaFisica.nomeCliente ? order.historicoPessoaFisica.nomeCliente : order.email}
                  </div>
                </td>
                <td className='tableLine'>
                  {statuses[order.id] && (
                    <BtnContents>
                      <ButtonReschedule onClick={() => { handleCancelClick(order.id, 'reschedule', paginatedOrders) }}>Reagendar</ButtonReschedule>
                      <ButtonDetails onClick={() => { handleOpenDetails(order.id) }}>Detalhes</ButtonDetails>
                      <ButtonCancel onClick={() => handleOpenModal(order.id)}>Cancelar</ButtonCancel>
                      {isOpenModal && (
                        <ContainerFixed>
                          {isCancel && <Loading loading={isCancel} />}
                          <ContentPopUp>
                            <Card>
                              <Title>Cancelar agendamento</Title>
                              <TextCard>Você deseja realmente cancelar o agendamento deste certificado?</TextCard>
                              <ContentButtons>
                                <ButtonClose onClick={() => setOpenModal(!isOpenModal)}>Não</ButtonClose>
                                <ButtonConsent onClick={() => handleCancelScheduling()}>Sim</ButtonConsent>
                              </ContentButtons>
                            </Card>
                          </ContentPopUp>
                        </ContainerFixed>
                      )}
                    </BtnContents>
                  )}
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Container>
      <Container>
        <Content className='content-buttons'>
          <Button disabled={selectedOrder ? false : true} onClick={handleScheduleClick}>Agendar</Button>
          {totalPages > 1 && (
            <ContentPaginate>
              <ButtonPaginate disabled={isFirstPage} onClick={() => handlePageChange(currentPage - 1)}>
                <Image src={ArrowLeft} alt='Anterior' />
              </ButtonPaginate>
              <span>{`Página ${currentPage} de ${totalPages}`}</span>
              <ButtonPaginate disabled={isLastPage} onClick={() => handlePageChange(currentPage + 1)}>
                <Image src={ArrowRight} alt='Próximo' />
              </ButtonPaginate>
            </ContentPaginate>
          )}
        </Content>
      </Container>
      {isActive && (
        <DetailsSchedule detailsSchedule={scheduleDetails} isActive={isActive} setActive={setActive} isLoading={isLoading} />
      )}
    </Fragment>
  );
};
