import styled from 'styled-components';

export const ContainerFixed = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
`;

export const ContentPopUp = styled.div`
	position: relative;
	max-width: 500px;
	width: 100%;
	height: 270px;
	background-color: var(--white);
	padding: 15px 30px;
	border-radius: 8px;

	@media screen and (max-width: 767px) {
		width: 90%;
		min-height: 270px;
	}
`;

export const ButtonClose = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: transparent;
	border: none;
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
  justify-content: center;
	max-width: 100%;
	width: 100%;
  height: 100%;
	background-color: var(--white);
	margin-right: 125px;
	line-height: 22px;
`;

export const TextCard = styled.p`
	color: var(--gray-900);
	font-weight: 400;
	font-size: 15px;
	padding: 0 5px;

	&.margin-top {
		margin-top: 16px;
	}
`;

export const ButtonConsent = styled.button`
	width: 150px;
	height: 44px;
	background-color: var(--ins-blue);
	color: var(--white);
	font-size: 16px;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	margin: 20px auto 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;

	&[disabled] {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
		cursor: default;
	}

	&:hover {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
	}
`;