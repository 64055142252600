import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 64px;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
`

export const Content = styled.div`
  max-width: 1295px;
  width: 100%;
  height: 100%;

  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`
export const LinkLogo = styled.a`
  text-decoration: none;
`