import { Container, Content, Logo, LinkLogo } from'./styles'
import logo from '../../assets/logo/Certisign.svg'

export const Header = () => {
  return (
    <Container>
      <Content>
        <LinkLogo href='https://certisign.com.br'>
          <Logo src={logo} alt='Logo Certisign' />
        </LinkLogo>
      </Content>
    </Container>
  )
}