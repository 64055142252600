import React, { useState } from 'react';
import { DetailsProps } from '../../../types/types';
import { ContainerFixed, ContentPopUp, ButtonClose, Card, TextCard, ButtonConsent } from './styles';
import Loading from '../../Loading';

interface DetailsScheduleProps {
  detailsSchedule: DetailsProps;
  isActive: boolean;
  setActive: React.Dispatch<boolean>
  isLoading: boolean;
}

const DetailsSchedule = ({ detailsSchedule, isActive, setActive, isLoading }: DetailsScheduleProps) => {
  const [isCpoy, setCopy] = useState<boolean>();

  const handleClose = () => {
    setActive(!isActive);
  }

  const handleCopy = () => {
    const textToCopy = `
      Nome do posto: ${detailsSchedule.nomePosto}
      Endereço: ${detailsSchedule.endereco}, ${detailsSchedule.numero} - ${detailsSchedule.bairro}
      Cidade: ${detailsSchedule.cidade} - ${detailsSchedule.estado}
      Data: ${detailsSchedule.dataAgendamento.split('-').reverse().join('/')}
      Horário: ${detailsSchedule.horaInicialAgendamento}
      Tipo de validação: ${detailsSchedule.tipo.descricao}
    `;
    navigator.clipboard.writeText(textToCopy);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  return (
    <>
      {isLoading ? <Loading loading={isLoading} /> : (
        <ContainerFixed>
          <ContentPopUp>
            <ButtonClose onClick={handleClose}>
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18.5872 2.27315C18.9648 1.8701 18.9441 1.23727 18.5411 0.85969C18.138 0.482109 17.5052 0.502758 17.1276 0.90581L9.85742 8.66647L2.58722 0.90581C2.20963 0.502758 1.57681 0.482109 1.17375 0.85969C0.770702 1.23727 0.750054 1.8701 1.12763 2.27315L8.48717 10.1292L1.12763 17.9852C0.750054 18.3882 0.770702 19.0211 1.17375 19.3986C1.57681 19.7762 2.20963 19.7556 2.58722 19.3525L9.85742 11.5919L17.1276 19.3525C17.5052 19.7556 18.138 19.7762 18.5411 19.3986C18.9441 19.0211 18.9648 18.3882 18.5872 17.9852L11.2277 10.1292L18.5872 2.27315Z" fill="black" />
              </svg>
            </ButtonClose>
            {detailsSchedule && (
              <Card className='fullWidth'>
                <TextCard><strong>Nome do posto:</strong> {detailsSchedule.nomePosto}</TextCard>
                <TextCard><strong>Endereço:</strong> {detailsSchedule.endereco}, {detailsSchedule.numero} - {detailsSchedule.bairro}</TextCard>
                <TextCard><strong>Cidade:</strong> {detailsSchedule.cidade} - {detailsSchedule.estado}</TextCard>
                <TextCard><strong>Data:</strong> {detailsSchedule.dataAgendamento.split('-').reverse().join('/')}</TextCard>
                <TextCard><strong>Horário:</strong> {detailsSchedule.horaInicialAgendamento}</TextCard>
                <TextCard><strong>Tipo de validação:</strong> {detailsSchedule.tipo.descricao}</TextCard>
                <ButtonConsent onClick={handleCopy} disabled={isCpoy}>{isCpoy ? 'Copiado!' : 'Copiar!'}</ButtonConsent>
              </Card>
            )}
          </ContentPopUp>
        </ContainerFixed>
      )}
    </>
  )
}

export default DetailsSchedule;