import Calendar from 'react-calendar';
import { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import { CalendarContent, ContentCol, TimeSlot, TitleTime, Select, ContentTime, ContainerData, ButtonData, ContainerDataCollapse, ButtonTime, ContainerTime, ContainerTimeCollapse } from './styles';
import { scheduleAvailability } from '../../../services/api';
import { useSchedule } from '../../../context/ScheduleContext';
import { useWindowSize } from '../../../hooks/useDevice';

interface ScheduleCalendarProps {
  orderId: string | undefined;
  localCode: number;
  error: string | null;
}

const ScheduleCalendar = ({ orderId, localCode, error }: ScheduleCalendarProps) => {
  const [isActive, setActive] = useState(false);
  const [scheduleData, setScheduleData] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [openDate, setOpenDate] = useState<boolean>()
  const [openTime, setOpenTime] = useState<boolean>()
  const { setData } = useSchedule();

  const { width } = useWindowSize()
  const isDevice = Number(width) < 980;

  const handleDropdown = () => {
    setActive(!isActive);
  }
  useEffect(() => {
    if (localCode && orderId) {
      scheduleAvailability.get(`/?idGar=${orderId}&postoCodigo=${localCode}`)
        .then((response) => {
          if (response?.data?.datas) {
            setScheduleData(response.data.datas);
          } else {
            console.error('Dados de agenda não encontrados na resposta');
          }
        })
        .catch((error) => {
          console.error('Erro ao carregar os dados da agenda', error);
        });
    }
  }, [orderId, localCode]);

  const isDateAvailable = (date: Date) => {
    const dateString = date.toISOString().split('T')[0];
    return scheduleData?.find((day) => day.data === dateString)?.horariosDiponiveis.length > 0;
  };

  const handleDateChange = (value: Date | Date[] | null) => {
    if (value === null) {
      setSelectedDate(null);
    } else if (Array.isArray(value)) {
      setSelectedDate(value[0]);
    } else {
      setSelectedDate(value);
    }
  };

  const handleSelectTime = (date: string, time: string) => {
    setSelectedTime(`${date} ${time}`);
  };

  const availableTimes = scheduleData?.find(
    (day) => day.data === selectedDate?.toISOString().split('T')[0]
  )?.horariosDiponiveis || [];
  useEffect(() => {
    if (selectedDate) {
      setData(prevState => ({
        ...prevState,
        hora: selectedTime?.split(' ')[1],
        data: selectedDate?.toISOString()?.split('T')[0],
        codigo: localCode,
        postoCodigo: localCode
      }));
    }
  }, [selectedDate, selectedTime, localCode, setData]);

  const handleOpenContent = (button: string) => {
    if (button === 'date') {
      setOpenDate(!openDate)
    }
    if (button === 'time') {
      setOpenTime(!openTime)
    }
  }

  return (
    <ContentCol>
      {!error && !isDevice && (
        <CalendarContent>
          {/* @ts-ignore */}
          <Calendar
            calendarType='hebrew'
            onChange={handleDateChange}
            value={selectedDate}
            tileClassName={({ date }) => {
              return isDateAvailable(date) ? 'available' : '';
            }}
          />
        </CalendarContent>
      )}
      {!error && !isDevice && availableTimes.length > 0 && (
        <CalendarContent>
          <TitleTime>Horário do atendimento</TitleTime>
          <Select $isActive={isActive} onClick={handleDropdown}>
            <TimeSlot>{selectedTime ? selectedTime.split(' ')[1] : 'Escolha o horário'}</TimeSlot>
            <ContentTime $isActive={isActive}>
              {availableTimes.map((time: string) => (
                <TimeSlot key={time} onClick={() => handleSelectTime(selectedDate?.toISOString().split('T')[0]!, time)}>
                  {time}
                </TimeSlot>
              ))}
            </ContentTime>
          </Select>
        </CalendarContent>
      )}
      {isDevice && (
        <>
          <ContainerData $isActive={openDate}>
            <ButtonData onClick={() => {handleOpenContent('date')}} $isActive={openDate}>Data</ButtonData>
            <ContainerDataCollapse>
              {!error && (
                <CalendarContent>
                  {/* @ts-ignore */}
                  <Calendar
                    calendarType='hebrew'
                    onChange={handleDateChange}
                    value={selectedDate}
                    tileClassName={({ date }) => {
                      return isDateAvailable(date) ? 'available' : '';
                    }}
                  />
                </CalendarContent>
              )}
            </ContainerDataCollapse>
          </ContainerData>
          <ContainerTime $isActive={openTime}>
            <ButtonTime onClick={() => {handleOpenContent('time')}} $isActive={openTime}>Horário</ButtonTime>
            <ContainerTimeCollapse>
              {!error && availableTimes.length > 0 && (
                <CalendarContent>
                  <TitleTime>Horário do atendimento</TitleTime>
                  <Select $isActive={isActive} onClick={handleDropdown}>
                    <TimeSlot>{selectedTime ? selectedTime.split(' ')[1] : 'Escolha o horário'}</TimeSlot>
                    <ContentTime $isActive={isActive}>
                      {availableTimes.map((time: string) => (
                        <TimeSlot key={time} onClick={() => handleSelectTime(selectedDate?.toISOString().split('T')[0]!, time)}>
                          {time}
                        </TimeSlot>
                      ))}
                    </ContentTime>
                  </Select>
                </CalendarContent>
              )}
            </ContainerTimeCollapse>
          </ContainerTime>
        </>
      )}
    </ContentCol>
  );
};

export default ScheduleCalendar;
